/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

 import React from "react"
 import { StaticQuery, graphql, Link } from "gatsby"
 
 import { Container } from "react-bootstrap"
 
 import Navbar from "./navBar"
 
 const Layout = ({ children, pageInfo, sideBarOptions, dropDownOptions, sideBarWidth }) => (
   <StaticQuery
     query={graphql`
       query SiteTitleQuery {
         site {
           siteMetadata {
             title
           }
         }
       }
     `}
     render={data => (
       <>
         <Container fluid className="px-0 main" style={{ height: "auto"}}>
           <Navbar pageInfo={pageInfo} additionalNavBar={dropDownOptions} />
             { sideBarOptions === undefined ? 
                <Container fluid id="main-cont2">
                  {children}
                </Container>
              : 
              <div className="d-flex flex-nowrap">
                <div id="sidebar-full" className="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{width: sideBarWidth}}>
                  {sideBarOptions}
                </div> 
                <div id="sidebar-content" className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white">
                  <div className="d-flex flex-column scrollarea">
                    <Container fluid>{children}</Container>
                  </div>
                </div>
              </div>
             }
         </Container>
         <Container fluid className="px-0">
             <div className="footer-col">
               <footer>
                 <span>
                 <Link to="/privacy-policy">Privacy Policy</Link><br/>
                 <Link to="/terms-and-conditions">Terms and Conditions</Link><br/>
                 <Link to="/about">About</Link>
                 <br />
                    ILoveItRecipes.com © {new Date().getFullYear()}
                 </span>
               </footer>
             </div>
         </Container>
       </>
     )}
   />
 )
 
 export default Layout