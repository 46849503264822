import React from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"
import { Link } from "gatsby"

export const main_categories = [
  { name: "Appetizers", singleName: "Appetizer", categories: ["appetizers", "cold-appetizers", "dips", "hot-appetizers", "snacks"],
        image: "appetizer", link: "/recipes/view/category/appetizers/",
        description: "Hot & cold appetizers, dips, snacks"},
  { name: "Baking", singleName: "Baking", categories: ["baking", "bars", "biscuits", "bread", "cakes", "cookies", "drop-cookies", "frosting-2", "muffins", "rolled-cookies"],
        image: "cake", link: "/recipes/view/category/baking/",
        description: "Biscuits, breads, cakes, cookies, muffins" },
  { name: "Beverages", singleName: "Beverage", categories: ["beverages", "cold-beverages", "hot-beverages"],
        image: "beverage", link: "/recipes/view/category/beverages/",
        description: "Hot & cold beverages" },
  { name: "Breakfast", singleName: "Breakfast", categories: ["breakfast"],
        image: "pancakes", link: "/recipes/view/category/breakfast/",
        description: "Pancakes, eggs, quiche" },
  { name: "Casseroles/Hotdish", singleName: "Casserole/hotdish", categories: ["casseroles"],
        image: "casserole", link: "/recipes/view/category/casseroles/",
        description: "Main dish casseroles, or hot-dishes as some people call them" },
  { name: "Cuisines", singleName: "Cuisine", categories: ["cuisines", "american", "asian", "cajun", "chinese", "european", "french", "german", "greek", "italian", "korean", "kwanzaa", 
                 "mexican", "middle-eastern", "native-american", "norwegian", "swedish"],
        image: "cuisines", link: "/recipes/view/category/cuisines/",
        description: "American, French, Italian, Mexican, etc." },
  { name: "Cooking Methods", singleName: "Cooking Method", categories: ["cooking-methods", "boiling", "broiling", "deep-frying", "dutch-oven-2", "pan-frying", "pressure-cooker", "roasting", "slow-cooker", "stir-frying"],
        image: "cooking-method", link: "/recipes/view/category/cooking-methods/",
        description: "Deep-frying, pan-frying, roasting, slow cooker, etc." },
  { name: "Chili/Soups/Stews", singleName: "Chili/Soup/Stew", categories: ["chili-soups-stews", "chili", "soups", "stew"],
        image: "soup", link: "/recipes/view/category/chili-soups-stews/",
        description: "Lots of classics" },
  { name: "Desserts/Pies", singleName: "Dessert & Pie", categories: ["desserts-pies", "cakes-2","cream-pies", "desserts",  "frosting", "frozen-desserts", "fruit-desserts-2", "fruit-pies", "meringue-pies", "pie-crusts", "pies"],
        image: "pie", link: "/recipes/view/category/desserts-pies/",
        description: "Many great desserts, pies, pie crusts" },
  { name: "Fruit", singleName: "Fruit", categories: ["fruit", "fruit-cakes", "fruit-desserts", "fruit-pies-2", "fruit-salads-2"],
        image: "watermelon", link: "/recipes/view/category/fruit/",
        description: "Fruit recipes, fruit cakes, desserts, pies, salads" },
  { name: "Kid Friendly", singleName: "Kid Friendly", categories: ["candy", "kid-desserts", "kid-friendly", "snacks-2"],
        image: "kid-friendly", link: "/recipes/view/category/kid-friendly/",
        description: "Kid friendly and fun recipes" },
  { name: "Meat/Fish/Seafood", singleName: "Meat, Fish, Seafood", categories: ["meat-fish-seafood", "beef", "chicken", "fish", "game", "ham", "pork", "sausage", "seafood", "turkey", "veal" ],
        image: "spare-ribs", link: "/recipes/view/category/meat-fish-seafood/",
        description: "Beef, chicken, fish, etc." },
  { name: "Occasions", singleName: "Occasion", categories: ["occasions", "birthday", "christmas", "easter", "football-games", "halloween", "labor-day", "mardi-gras", "memorial-day", 
                  "new-years", "quick-meals-2", "thanksgiving", "valentines-day", "wedding"],
        image: "holiday2", link: "/recipes/view/category/occasions/",
        description: "Birthdays, holidays, football games, etc." },
  { name: "Outdoor Cooking", singleName: "Outdoor Cooking", categories: ["outdoor-cooking", "camping", "dutch-oven", "grilling-and-bbq", "picnics"],
        image: "outdoor2", link: "/recipes/view/category/outdoor-cooking/",
        description: "Camping, grilling, picnics, and Dutch Oven recipes" },
  { name: "Pasta", singleName: "Pasta", categories: ["pasta"],
        image: "pasta", link: "/recipes/view/category/pasta/",
        description: "All things pasta" }, 
  { name: "Quick Meals", singleName: "Quick Meal", categories: ["quick-meals"],
        image: "stopwatch2", link: "/recipes/view/category/quick-meals/",
        description: "Meals you can make fast" }, 
  { name: "Sandwiches", singleName: "Sandwich", categories: ["sandwiches", "cold-sandwiches", "hot-sandwiches"],
        image: "sandwich", link: "/recipes/view/category/sandwiches/",
        description: "Cold & hot sandwiches" },
  { name: "Special Categories", singleName: "Special Category", categories: ["special", "egg-free", "high-fiber", "high-vitamin", "low-calorie", "low-fat", "nut-free", "peanut-free"],
        image: "nut-free", link: "/recipes/view/category/special/",
        description: "Egg-free, nut-free, high-fiber, low-calorie, sugar-free, etc." },
  { name: "Salads", singleName: "Salad", categories: ["salads", "fruit-salads", "tossed-salads", "vegetable-salads"],
        image: "salad", link: "/recipes/view/category/salads/",
        description: "Salads, tossed salads, fruit salads, etc." },
  { name: "Side Dishes", singleName: "Side Dish", categories: ["side-dish", "potatoes", "rice-grains"],
        image: "side-dish", link: "/recipes/view/category/side-dish/",
        description: "Potatoes, rice, grains, etc." },
  { name: "Vegetables", singleName: "Vegetable", categories: ["vegetables", "beans-legumes", "vegetable-salads-2", "vegan", "vegetarian"],
        image: "peppers", link: "/recipes/view/category/vegetables/",
        description: "Vegetables, Vegetarian, Vegan, beans, legumes,  etc." },
  { name: "This & That", singleName: "This & That", categories: ["this-and-that", "canning", "gourmet", "non-food-items", "sauces", "seasonings", "snacks-3"],
        image: "other", link: "/recipes/view/category/this-and-that/",
        description: "Canning, non-food items, and more" },
]

export const categories = [
      { name: "American", link: "american", normalized: "american"},
      { name: "Appetizers", link: "appetizers", normalized: "appetizer"},
      { name: "Asian", link: "asian", normalized: "asian"},
      { name: "Baking", link: "baking", normalized: "baking"},
      { name: "Bars", link: "bars", normalized: "bar"},
      { name: "Beans, Legumes", link: "beans-legumes", normalized: "bean"},
      { name: "Beef", link: "beef", normalized: "beef"},
      { name: "Beverages", link: "beverages", normalized: "beverage"},
      { name: "Birthday", link: "birthday", normalized: "birthday"},
      { name: "Biscuits", link: "biscuits", normalized: "biscuit"},
      { name: "Boiling", link: "boiling", normalized: "boiling"},
      { name: "Bread", link: "bread", normalized: "bread"},
      { name: "Breakfast", link: "breakfast", normalized: "breakfast"},
      { name: "Broiling", link: "broiling", normalized: "broiling"},
      { name: "Cajun", link: "cajun", normalized: "cajun"},
      { name: "Cakes", link: "cakes", normalized: "cake"},
      { name: "Cakes", link: "cakes-2", normalized: "cake"},
      { name: "Camping", link: "camping", normalized: "camping"},
      { name: "Candy", link: "candy", normalized: "candy"},
      { name: "Canning", link: "canning", normalized: "canning"},
      { name: "Casseroles", link: "casseroles", normalized: "casserole"},
      { name: "Chicken", link: "chicken", normalized: "chicken"},
      { name: "Chili", link: "chili", normalized: "chili"},
      { name: "Chinese", link: "chinese", normalized: "chinese"},
      { name: "Christmas", link: "christmas", normalized: "christmas"},
      { name: "Cold Appetizers", link: "cold-appetizers", normalized: "cold appetizer"},
      { name: "Cold Beverages", link: "cold-beverages", normalized: "cold beverage"},
      { name: "Cold Sandwiches", link: "cold-sandwiches", normalized: "cold sandwich"},
      { name: "Cookies", link: "cookies", normalized: "cookie"},
      { name: "Cream Pies", link: "cream-pies", normalized: "cream pie"},
      { name: "Deep Frying", link: "deep-frying", normalized: "deep fry"},
      { name: "Desserts", link: "desserts", normalized: "dessert"},
      { name: "Dips", link: "dips", normalized: "dip"},
      { name: "Drop Cookies", link: "drop-cookies", normalized: "drop cookie"},
      { name: "Dutch Oven", link: "dutch-oven", normalized: "dutch oven"},
      { name: "Dutch Oven", link: "dutch-oven-2", normalized: "dutch oven"},
      { name: "Easter", link: "easter", normalized: "easter"},
      { name: "Egg-Free", link: "egg-free", normalized: "egg free"},
      { name: "European", link: "european", normalized: "european"},
      { name: "Fish", link: "fish", normalized: "fish"},
      { name: "Football Games", link: "football-games", normalized: "football game"},
      { name: "French", link: "french", normalized: "french"},
      { name: "Frosting", link: "frosting", normalized: "frosting"},
      { name: "Frosting", link: "frosting-2", normalized: "frosting"},
      { name: "Frozen Desserts", link: "frozen-desserts", normalized: "frozen dessert"},
      { name: "Fruit", link: "fruit", normalized: "fruit"},
      { name: "Fruit Cakes", link: "fruit-cakes", normalized: "fruit cake"},
      { name: "Fruit Desserts", link: "fruit-desserts", normalized: "fruit dessert"},
      { name: "Fruit Desserts", link: "fruit-desserts-2", normalized: "fruit dessert"},
      { name: "Fruit Pies", link: "fruit-pies", normalized: "fruit pie"},
      { name: "Fruit Pies", link: "fruit-pies-2", normalized: "fruit pie"},
      { name: "Fruit Salads", link: "fruit-salads", normalized: "fruit salad"},
      { name: "Fruit Salads", link: "fruit-salads-2", normalized: "fruit salad"},
      { name: "Game", link: "game", normalized: "game"},
      { name: "German", link: "german", normalized: "german"},
      { name: "Gourmet", link: "gourmet", normalized: "gourmet"},
      { name: "Greek", link: "greek", normalized: "greek"},
      { name: "Grilling and BBQ", link: "grilling-and-bbq", normalized: "grill"},
      { name: "Halloween", link: "halloween", normalized: "halloween"},
      { name: "Ham", link: "ham", normalized: "ham"},
      { name: "High-Fiber", link: "high-fiber", normalized: "high fiber"},
      { name: "High-Vitamin", link: "high-vitamin", normalized: "high vitamin"},
      { name: "Hot Appetizers", link: "hot-appetizers", normalized: "hot appetizer"},
      { name: "Hot Beverages", link: "hot-beverages", normalized: "hot beverage"},
      { name: "Hot Sandwiches", link: "hot-sandwiches", normalized: "hot sandwiche"},
      { name: "Italian", link: "italian", normalized: "italian"},
      { name: "Kid Desserts", link: "kid-desserts", normalized: "kid dessert"},
      { name: "Kid Friendly", link: "kid-friendly", normalized: "kid friendly"},
      { name: "Korean", link: "korean", normalized: "korean"},
      { name: "Kwanzaa", link: "kwanzaa", normalized: "kwanzaa"},
      { name: "Labor Day", link: "labor-day", normalized: "labor day"},
      { name: "Low-Calorie", link: "low-calorie", normalized: "low calorie"},
      { name: "Low-Fat", link: "low-fat", normalized: "low fat"},
      { name: "Mardi Gras", link: "mardi-gras", normalized: "mardi gras"},
      { name: "Memorial Day", link: "memorial-day", normalized: ""},
      { name: "Meringue Pies", link: "meringue-pies", normalized: "meringue pie"},
      { name: "Mexican", link: "mexican", normalized: "mexican"},
      { name: "Middle Eastern", link: "middle-eastern", normalized: "middle eastern"},
      { name: "Muffins", link: "muffins", normalized: "muffin"},
      { name: "Native American", link: "native-american", normalized: "native american"},
      { name: "New Year's", link: "new-years", normalized: "new year"},
      { name: "Non-Food Items", link: "non-food-items", normalized: "non-food item"},
      //{ name: "North American", link: "north-american", normalized: ""},
      { name: "Norwegian", link: "norwegian", normalized: "norwegian"},
      { name: "Nut-Free", link: "nut-free", normalized: "nut free"},
      { name: "Pan Frying", link: "pan-frying", normalized: "pan fry"},
      { name: "Pasta", link: "pasta", normalized: "pasta"},
      { name: "Peanut-Free", link: "peanut-free", normalized: "peanut free"},
      { name: "Picnics", link: "picnics", normalized: "picnic"},
      { name: "Pie Crusts", link: "pie-crusts", normalized: "pie crust"},
      { name: "Pies", link: "pies", normalized: "pie"},
      { name: "Pork", link: "pork", normalized: "pork"},
      { name: "Potatoes", link: "potatoes", normalized: "potato"},
      { name: "Pressure Cooker", link: "pressure-cooker", normalized: "pressure cooker"},
      //{ name: "Punches", link: "punches", normalized: ""},
      { name: "Quick Meals", link: "quick-meals", normalized: "quick meal"},
      { name: "Quick Meals", link: "quick-meals-2", normalized: "quick meal"},
      { name: "Rice/Grains", link: "rice-grains", normalized: "rice"},
      { name: "Roasting", link: "roasting", normalized: "roast"},
      { name: "Rolled Cookies", link: "rolled-cookies", normalized: "rolled cookie"},
      { name: "Salads", link: "salads", normalized: "salad"},
      { name: "Sandwiches", link: "sandwiches", normalized: "sandwich"},
      { name: "Sauces", link: "sauces", normalized: "sauce"},
      { name: "Sausage", link: "sausage", normalized: "sausage"},
      { name: "Seafood", link: "seafood", normalized: "seafood"},
      { name: "Seasonings", link: "seasonings", normalized: "seasoning"},
      { name: "Side Dish", link: "side-dish", normalized: "side dish"},
      { name: "Slow Cooker", link: "slow-cooker", normalized: "slow cooker"},
      { name: "Snacks", link: "snacks", normalized: "snack"},
      { name: "Snacks", link: "snacks-2", normalized: "snack"},
      { name: "Snacks", link: "snacks-3", normalized: "snack"},
      { name: "Soups", link: "soups", normalized: "soup"},
      //{ name: "Spanish", link: "spanish", normalized: ""},
      { name: "Stew", link: "stew", normalized: "stew"},
      { name: "Stir Frying", link: "stir-frying", normalized: "stir fry"},
      //{ name: "Sugar-Free", link: "sugar-free", normalized: ""},
      { name: "Swedish", link: "swedish", normalized: "swedish"},
      { name: "Thanksgiving", link: "thanksgiving", normalized: "thanksgiving"},
      { name: "This & That", link: "this-and-that", normalized: "this and that"},
      { name: "Tossed Salads", link: "tossed-salads", normalized: "tossed salad"},
      { name: "Turkey", link: "turkey", normalized: "turkey"},
      //{ name: "Uncategorized", link: "uncategorized", normalized: ""},
      { name: "Valentine's Day", link: "valentines-day", normalized: "valentine day"},
      { name: "Veal", link: "veal", normalized: "veal"},
      { name: "Vegan", link: "vegan", normalized: "vegan"},
      { name: "Vegetable Salads", link: "vegetable-salads", normalized: "vegetable salad"},
      { name: "Vegetable Salads", link: "vegetable-salads-2", normalized: "vegetable salad"},
      { name: "Vegetables", link: "vegetables", normalized: "vegetable"},
      { name: "Vegetarian", link: "vegetarian", normalized: "vegetarian"},
      { name: "Wedding", link: "wedding", normalized: "wedding"},
]

export const categoryInfo = (category_uri) => {

  const words = category_uri.split('/')
  const link = words[4]

  const link_prefix = "/recipes/view/category/"
  console.log('category link='+link)
  const main_category_matches = main_categories.filter(rec => {
    return rec.categories.includes(link)
  })
  const main_category = main_category_matches[0]
  const category_matches = categories.filter(rec => {
    return main_category.categories.includes(rec.link)
  })

  console.log('main_category.link='+main_category.link);
  console.log('link_prefix+link='+link_prefix+link);

  const sideBarOptions = 
    <><div className="category-menu-top"><Link to="/recipe-index">Recipe Categories</Link></div>
      <div className="category-menu-top d-flex">
        {main_category.link === link_prefix+link+'/' ? (
              <div className="category-menu-block-current me-1">&nbsp;&nbsp;</div>
        ) : (
          <div className="category-menu-block me-1">&nbsp;&nbsp;</div>
        )}
        <Link to={main_category.link}>{main_category.name}</Link>
      </div>
        {category_matches.map( cat => (
        link_prefix+cat.link+'/' === main_category.link ? 
            <></> 
            :
            <div key={cat.link} className="d-flex category-menu-links ps-2">
            {cat.link === link ? (
              <div className="category-menu-block-current me-1">&nbsp;&nbsp;</div>
            ) : (
              <div className="category-menu-block me-1">&nbsp;&nbsp;</div>
            )}
            <div>
              <Link className="me-1" to={link_prefix+cat.link+'/'}>
                {cat.name}
              </Link>
            </div>
            </div>
        ))
        }
    </>

  const dropDownOptions = 
    <DropdownButton title={main_category.singleName + " Categories"}>
        {category_matches.map( cat => (
        <Dropdown.Item href={link_prefix+cat.link+'/'}>{cat.name}</Dropdown.Item>
            ))
        }
    </DropdownButton>

  return { sideBarOptions, dropDownOptions, mainCategoryName: main_category.name, mainCategoryLink: main_category.link }
}
